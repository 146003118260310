import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import "./public/styles/base.styl";
import "./public/js/config";
import mavonEditor from "mavon-editor";
import "mavon-editor/dist/css/index.css";

Vue.use(mavonEditor);
Vue.config.productionTip = false;
Vue.config.errorHandler = function (err, vm, info) {
  Vue.prototype.$message.error(`Error: ${err.toString()}\nInfo: ${info}`);
  ElementUI.Message.error(`Error: ${err.toString()}\nInfo: ${info}`);
};

// 也可以处理Promise中的未捕获的拒绝
window.addEventListener("unhandledrejection", (event) => {
  Vue.prototype.$message.error(`Unhandled rejection: ${event.reason}`);
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

<template>
  <div class="foot flex ai-c jc-c">
    <div class="foot-item">
      <div > © 2024-2026 粤港澳自然教育 版权所有 </div>
      <div>
        ICP证：<a href="http://beian.miit.gov.cn/" target="_blank"
          >粤ICP备2024267156号</a
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang="stylus">
.foot
  // background #ffffff
  .foot-item
    width 90%
    font-size 12px
    color #858585
    text-align center
    padding 15px 0

  & a
    color #5cadff
    text-decoration none

  & a:hover
    color #5cadff
    text-decoration underline
</style>

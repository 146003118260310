import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: resolve => require(['@/views/Home.vue'], resolve),
  },
  {
    path: '/talent/comparison',
    component: resolve => require(['@/views/Comparison.vue'], resolve),
  },
  {
    path: '/talent_pool',
    component: resolve => require(['@/views/TalentPool.vue'], resolve),
  },
  {
    path: '/register',
    component: resolve => require(['@/views/Register.vue'], resolve),
  },
  {
    path: '/about',
    component: resolve => require(['@/views/About.vue'], resolve),
  },
  {
    path: '/home',
    component: resolve => require(['@/views/Home.vue'], resolve),
  },
  {
    path: '/talent',
    component: resolve => require(['@/views/Talent.vue'], resolve),
  },
  {
    path: '/examine',
    component: resolve => require(['@/views/Examine.vue'], resolve),
  },
  {
    path: '/user',
    component: resolve => require(['@/views/User.vue'], resolve),
  },
  {
    path: '/article/editor',
    component: resolve => require(['@/views/ArticleEditor.vue'], resolve),
  },
  {
    path: '/article/list',
    component: resolve => require(['@/views/ArticleList.vue'], resolve),
  }, {
    path: '/article/detail',
    component: resolve => require(['@/views/ArticleDetail.vue'], resolve),
  },{
    path: '*',
    redirect: "/"
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
 
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (to) {
  return VueRouterPush.call(this, to).catch(err => err)
}
export default router

<template>
  <div id="app">
    <Head></Head>
    <div id="nav"></div>

    <router-view />
    <Foot></Foot>
  </div>
</template>
<script>
import Head from "@/components/Head.vue";
import Foot from "@/components/Foot.vue";

export default {
  name: "Home",
  components: {
    Head,
    Foot,
  },
};
</script>

<style lang="stylus">
#app
  font-family Avenir, Helvetica, Arial, sans-serif
  -webkit-font-smoothing antialiased
  -moz-osx-font-smoothing grayscale
  text-align center
  color #2c3e50
  margin-top 60px
  background #f3f3f3


</style>

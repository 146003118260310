import BASE_URL from "./baseUrl";
import axios from "./config"; // 导入http中创建的axios实例

let request = {
  PasswordLogin(param) {
    return axios({
      method: "post",
      url: `${BASE_URL.public}/account/login`,
      data: param,
    });
  },
  Register(param) {
    return axios({
      method: "post",
      url: `${BASE_URL.public}/account/register`,
      data: param,
    });
  },
  ResetPassword(param) {
    return axios({
      method: "post",
      url: `${BASE_URL.public}/account/resetpas`,
      data: param,
    });
  },
  //----
  GetParamList(param) {
    return axios({
      method: "get",
      url: `${BASE_URL.public}/account/param`,
      params: param,
      
    });
  },
  GetAccountList(param) {
    return axios({
      method: "get",
      url: `${BASE_URL.public}/account/list`,
      params: param,
      
    });
  },
  GetAccount(param) {
    return axios({
      method: "get",
      url: `${BASE_URL.public}/user/account`,
      params: param,
    });
  },
  GetUserList(param) {
    return axios({
      method: "get",
      url: `${BASE_URL.public}/user/list`,
      params: param,
      
    });
  },
  ExamineUser(param) {
    return axios({
      method: "post",
      url: `${BASE_URL.public}/user/examine`,
      data: param,
      
    });
  },
  ResetUserInfo(param) {
    return axios({
      method: "post",
      url: `${BASE_URL.public}/user/info`,
      data: param,
    });
  },
  GetUserInfo(param) {
    return axios({
      method: "get",
      url: `${BASE_URL.public}/user/info`,
      params: param,
    });
  },
  //-----
  GetArticleList(param) {
    return axios({
      method: "get",
      url: `${BASE_URL.public}/article/list`,
      params: param,
    });
  },
  DeleteArticle(param) {
    return axios({
      method: "get",
      url: `${BASE_URL.public}/article/delete`,
      params: param,
    });
  },
  GetArticle(param) {
    return axios({
      method: "get",
      url: `${BASE_URL.public}/article`,
      params: param,
    });
  },
  CreateArticle(param) {
    return axios({
      method: "post",
      url: `${BASE_URL.public}/article`,
      data: param,
    });
  },
  //----------
   //获取验证码
   GetCode(param) {
    return axios({
      method: "get",
      url: `${BASE_URL.public}/sendsms`,
      params: param,
    });
  },
  //上传图片
  UploadImage(param) {
    return axios({
      method: "post",
      url: `${BASE_URL.public}/upload/image`,
      data: param,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  //上传文件
  UploadFile(param) {
    return axios({
      method: "post",
      url: `${BASE_URL.public}/upload`,
      data: param,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
};

export default {
  request,
};

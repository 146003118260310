import Vue from "vue";
import ElementUI from "element-ui";
import axios from "axios";
import "element-ui/lib/theme-chalk/index.css";
import api from "@/public/js/base.js";
import BaseUrl from "@/public/js/baseUrl.js";

Vue.config.productionTip = false;
Vue.prototype.$api = api.request;
Vue.prototype.$BaseUrl = BaseUrl;
Vue.use(ElementUI);

let http = axios.create();
http.defaults.headers["Content-Type"] = "application/json";
http.interceptors.response.use((response) => {
  if (response.data.code == 20000) {
    return response;
  } else {
    setTimeout(() => {
      ElementUI.Message.error(response.data.message);
    }, 300);
    return response;
  }
  (error) => {
    ElementUI.Message.error(error);
    return error;
  };
});
http.interceptors.request.use((request) => {
  return request;
});
http.interceptors.request.use(
  (request) => {
    // if (request.url.indexOf("user") != -1) {
    //   request.headers["X-Token"] = localStorage.getItem("YCAdminToken");
    // }
    if (localStorage.getItem("YCAdminToken") != null) {
      request.headers["X-Token"] = localStorage.getItem("YCAdminToken");
    }

    return request;
  },
  (error) => {
    ElementUI.Message.error(error);
  }
);
export default http;

<template>
  <div>
    <header>
      <div class="flex jc-sb ai-c" :class="isTop ? '' : 'topbg'">
        <div class="logo">
          <img src="../public/images/logo.png" alt="" />
          <img src="../public/images/title.png" alt="" />
        </div>
        <div class="menu-icon">
          <el-dropdown
            v-show="token != ''"
            @command="handleCommand"
            trigger="click"
          >
            <el-avatar :size="30" :src="avatar" class="avatar-login">
              <img
                src="https://yichuang-talent-pool.oss-cn-guangzhou.aliyuncs.com/system-document/non-per.png"
                alt=""
              />
            </el-avatar>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="a">个人资料</el-dropdown-item>
              <el-dropdown-item command="b">修改密码</el-dropdown-item>
              <el-dropdown-item command="c">退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <div
            class="button-login"
            v-show="token == ''"
            @click="loginDialog = true"
          >
            登录
          </div>

          <i
            class="line el-icon-s-operation"
            @click="isShowMobileNav = true"
          ></i>
        </div>
        <nav class="flex ai-c jc-sb">
          <div class="flex ai-c" style="flex: 1">
            <div
              class="sm-title-logo flex ai-c c-p"
              :class="isChoose == 'tab1' ? 'choose' : ''"
              @click="$router.push('/home')"
            >
              <div class="title">首页</div>
            </div>
            <div
              class="sm-title-logo flex ai-c c-p"
              :class="isChoose == 'tab2' ? 'choose' : ''"
              @click="$router.push('/talent_pool')"
            >
              <div class="title">人才展示</div>
            </div>
            <div
              class="sm-title-logo flex ai-c c-p"
              :class="isChoose == 'tab3' ? 'choose' : ''"
              @click="$router.push('/about')"
            >
              <div class="title">关于我们</div>
            </div>
          </div>
          <div style="margin-right: 20px" class="flex ai-c">
            <el-input
              placeholder="请输入搜索关键词"
              v-model="keyword"
              clearable
              style="width: 200px; margin: 10px"
              @change="SearchArt()"
            >
              <i slot="prefix" class="el-input__icon el-icon-search"></i>
            </el-input>
            <el-button
              v-show="token == ''"
              style="background: #309432; color: #ffffff"
              @click="loginDialog = true"
              >登录</el-button
            >
            <el-dropdown
              v-show="token != ''"
              @command="handleCommand"
              trigger="click"
            >
              <el-avatar :size="40" :src="avatar">
                <img
                  src="https://yichuang-talent-pool.oss-cn-guangzhou.aliyuncs.com/system-document/non-per.png"
                  alt=""
                />
              </el-avatar>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="a">个人资料</el-dropdown-item>
                <el-dropdown-item command="b">修改密码</el-dropdown-item>
                <el-dropdown-item command="c">退出登录</el-dropdown-item>
                <el-dropdown-item command="d" v-if="role == 10"
                  >审核资料</el-dropdown-item
                >
                <el-dropdown-item command="e" v-if="role == 10"
                  >管理动态</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </nav>
      </div>
    </header>
    <div class="mobile-nav" :class="isShowMobileNav ? 'mobile-nav-show' : ''">
      <i class="el-icon-s-operation" style="padding: 20px" :size="30"></i>
      <div
        class="sm-title-logo flex ai-c c-p"
        :class="isChoose == 'tab1' ? 'choose' : ''"
        @click="$router.push('/home')"
      >
        <div class="title">首页</div>
      </div>
      <div
        class="sm-title-logo flex ai-c c-p"
        :class="isChoose == 'tab2' ? 'choose' : ''"
        @click="$router.push('/talent_pool')"
      >
        <div class="title">人才展示</div>
      </div>
      <div
        class="sm-title-logo flex ai-c c-p"
        :class="isChoose == 'tab3' ? 'choose' : ''"
        @click="$router.push('/about')"
      >
        <div class="title">关于我们</div>
      </div>
    </div>
    <div
      class="mobile-nav-mask"
      v-if="isShowMobileNav"
      @click="isShowMobileNav = false"
    ></div>
    <el-dialog
      title="登录"
      :visible.sync="loginDialog"
      :modal-append-to-body="false"
      :width="dialogWidth"
    >
      <div class="dialog-content flex fd-c jc-fs">
        <div class="input-group">
          <div class="label">登录手机：</div>
          <el-input
            v-model="phone"
            autocomplete="off"
            maxlength="11"
            minlength="11"
            class="input-field"
            style="width:70%"
            @input="
              (value) => {
                JudgeNumFn(value, 'phone');
              }
            "
          ></el-input>
        </div>
        <div class="input-group">
          <div class="label">登录密码：</div>
          <el-input
            v-model="password"
            autocomplete="off"
            show-password
            style="width:70%"
            class="input-field"
          ></el-input>
        </div>
      </div>
      <el-button class="login-button" @click="LoginFn()">登录</el-button>
      <div class="actions">
        <div class="forgot-password" @click="resetDialog = true">忘记密码</div>
        <div class="register" @click="JumpRe()">注册账号</div>
      </div>
    </el-dialog>
    <el-dialog
      title="重置密码"
      :visible.sync="resetDialog"
      :modal-append-to-body="false"
      :width="dialogWidth"
    >
      <div class="dialog-content">
        <div class="input-group">
          <div class="label">手机号：</div>
          <el-input
            v-model="phone"
            autocomplete="off"
            type="tel"
            maxlength="11"
            minlength="11"
            class="input-field"
            @input="
              (value) => {
                JudgeNumFn(value, 'phone');
              }
            "
          ></el-input>
        </div>
        <div class="input-group">
          <div class="label">重置密码：</div>
          <el-input
            v-model="password"
            autocomplete="off"
            :show-password="true"
            class="input-field"
            @input="
              (value) => {
                JudgeNumFn(value, 'password');
              }
            "
          ></el-input>
        </div>
        <div class="input-group">
          <div class="label">确认密码：</div>
          <el-input
            v-model="confirm_password"
            autocomplete="off"
            :show-password="true"
            class="input-field"
            @input="
              (value) => {
                JudgeNumFn(value, 'confirm_password');
              }
            "
          ></el-input>
        </div>
        <div class="input-group flex ai-c">
          <div class="label">验证码：</div>
          <el-input
            v-model="code"
            autocomplete="off"
            class="input-field"
          ></el-input>
          <span
            class="link"
            style="padding-left: 20px"
            v-if="reciprocalNum == 0"
            @click="GetCode()"
            >获取验证码</span
          >
          <span v-else style="color: #858585; padding-left: 20px">
            {{ reciprocalNum }}s后重新获取
          </span>
        </div>
      </div>
      <el-button class="submit-button" @click="ResetPassword()"
        >确定重置</el-button
      >
    </el-dialog>
  </div>
</template>

<script>
export default {
  watch: {
    $route(to, from) {
      this.isShowMobileNav = false;
      this.GetColor();
      if (to.fullPath.indexOf("article/list?keyword") != -1) {
        this.keyword = this.$route.query.keyword;
      }
    },
  },
  data() {
    return {
      dialogWidth: window.innerWidth <= 768 ? "90vw" : "30vw",
      isShowMobileNav: false,
      role: 0,
      isTop: false,
      isChoose: "tab1",
      keyword: "",
      loginDialog: false,
      phone: "",

      password: "",
      token: "",
      avatar: "",

      resetDialog: false,
      reciprocalNum: 0,
      code: "",
      confirm_password: "",
    };
  },
  methods: {
    SearchArt() {
      this.$router.push(`/article/list?keyword=${this.keyword}`);
    },
    handleCommand(command) {
      if (command == "a") {
        this.$router.push("/user?type=user");
        this.$forceUpdate();
      } else if (command == "b") {
        this.$router.push("/user?type=modify");
        this.$forceUpdate();
      } else if (command == "c") {
        this.LoginOut();
      } else if (command == "d") {
        this.$router.push("/examine");
      } else if (command == "e") {
        this.$router.push("/article/editor");
      }
    },
    LoginOut() {
      this.$router.push("/home");
      localStorage.clear();
      this.$message.success("已退出");
      this.GetColor();
      this.$forceUpdate();
    },
    PasswordCheck(e) {
      if (e != "") {
        if (!/[0-9]/.test(e)) {
          this.$message("登录密码必须包含数字");
          return true;
        } else if (!/[a-z]/.test(e) && !/[A-Z]/.test(e)) {
          this.$message("登录密码必须包含英文");
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    JudgeNumFn(e, key) {
      this.$nextTick(() => {
        this[key] = this[key].replace(/[\u4e00-\u9fa5]/g, "");
        this[key] = this[key].replace(/(^\s*)|(\s*$)/g, "");
      });
      this.$forceUpdate();
    },
    GetCode() {
      if (this.phone == "") {
        this.$message("请输入手机号");
      } else if (this.phone.length != 11) {
        this.$message("请输入11位手机号");
      } else {
        this.$api
          .GetCode({
            phone: this.phone,
          })
          .then((data) => {
            if (data.data.code == 20000) {
              this.reciprocalNum = 60;
              let timer = setInterval(() => {
                if (this.reciprocalNum > 0) {
                  this.reciprocalNum--;
                } else {
                  clearInterval(timer);
                }
              }, 1000);
              this.$message.success("已发送验证码");
            }
          });
      }
    },
    JumpRe() {
      this.$router.push("/register");
      this.isLoading = false;
      this.loginDialog = false;
      this.resetDialog = false;
    },
    GetColor() {
      this.token = localStorage.getItem("YCAdminToken");
      if (this.token == null) {
        this.token = "";
      }
      this.role = localStorage.getItem("YCAdminRole");
      if (this.role == null) {
        this.role = 0;
      }
      this.avatar = localStorage.getItem("YCAdminAvatar");
      if (this.avatar == null) {
        this.avatar = "";
      }
      if (this.$route.fullPath.indexOf("/home") != -1) {
        this.isChoose = "tab1";
      } else if (this.$route.fullPath.indexOf("/talent") != -1) {
        this.isChoose = "tab2";
      } else if (this.$route.fullPath.indexOf("/about") != -1) {
        this.isChoose = "tab3";
      }
    },
    LoginFn() {
      if (this.phone == "") {
        this.$message("请输入手机号");
      } else if (this.phone.length != 11) {
        this.$message("请输入11位手机号");
      } else if (this.password == "") {
        this.$message("请输入密码");
      } else {
        this.isLoading = true;
        this.$api
          .PasswordLogin({
            phone: this.phone,
            password: this.password,
          })
          .then((data) => {
            if (data.data.code == 20000) {
              localStorage.setItem("YCAdminToken", data.data.data.token);
              localStorage.setItem("YCAdminName", data.data.data.name);
              localStorage.setItem("YCAdminNickName", data.data.data.nick_name);
              localStorage.setItem("YCAdminAvatar", data.data.data.avatar);
              localStorage.setItem("YCAdminPhone", data.data.data.phone);
              localStorage.setItem("YCAdminRole", data.data.data.role);
              this.avatar = data.data.data.avatar;
              this.loginDialog = false;
              this.$message({
                message: "登录成功",
                type: "success",
              });

              this.$router.push("/home");
              this.GetColor();
            }
            this.isLoading = false;
          });
      }
    },
    ResetPassword() {
      if (this.phone == "") {
        this.$message("请填写手机号");
      } else if (this.phone.length != 11) {
        this.$message("请填写11位手机号");
      } else if (this.password == "") {
        this.$message("请填写重置密码");
      } else if (this.password.length < 8) {
        this.$message("密码至少8位字符");
      } else if (this.PasswordCheck(this.password)) {
      } else if (this.confirm_password == "") {
        this.$message("请填写确认密码");
      } else if (this.confirm_password != this.password) {
        this.$message("两次密码输入不一致");
      } else if (this.code == "") {
        this.$message("请填写验证码");
      } else {
        this.$api
          .ResetPassword({
            phone: this.phone,
            code: this.code,
            password: this.password,
            confirm_password: this.confirm_password,
          })
          .then((data) => {
            if (data.data.code == 20000) {
              this.$message.success("重置成功，请重新登录");
              this.phone = "";
              this.password = "";
              this.confirm_password = "";
              this.code = "";
              this.resetDialog = false;
            }
          });
      }
    },
  },
  mounted() {
    this.GetColor();
  },
};
</script>
<style lang="stylus" scoped>
.topbg
  background $color-menu-background !important
  transition .5s

header
  position fixed
  top 0
  right 0
  left 0
  height 70px
  z-index 90
  background #f8f8f9
  transition .5s
  border-bottom 5px solid #309432

  .menu-icon
    display none

  nav
    flex 1

    .nav-item
      flex 1
      color #ffffff
      text-align center
      cursor pointer
      font-size 16px
      position relative

  .logo
    margin 0 40px
    min-width 138px

    & img
      width auto
      height 70px

  .sm-title-logo
    padding 20px
    margin 0 20px 0 20px

    .title, & img
      width auto
      height 30px

  .choose
    background #309432
    color #FFFFFF

.link
  color #5cadff
  cursor pointer

.mobile-nav
  display none

.login-button
  background #309432
  color #ffffff
  margin-bottom 10px
  width 100%

.actions
  display flex
  justify-content space-between
  width 100%

.forgot-password, .register
  cursor pointer

.register
  color #309432

@media screen and (max-width 768px)
  header
    padding 0
    z-index 1000
    border-bottom 5px solid #309432

    .menu-icon
      display flex
      align-items center
      padding-right 10px

      .button-login
        padding-right 10px
        margin-right 10px
        color #309432

      .avatar-login
        padding-right 10px
        margin-right 10px

      .line
        border-left 1px solid #309432
        padding-left 10px

    .logo
      min-width 138px
      margin 0 auto 0 10px
      position relative

      & img
        width auto
        // height 60px

  nav
    display none

  .mobile-nav
    display block
    position fixed
    right -200px
    bottom 0
    top 0
    background #ffffff
    width 200px
    z-index 1000
    transition .3s

    .sm-title-logo
      font-size 18px
      padding 20px 30px
      border-bottom 1px solid #eee
      font-weight bold
      overflow hidden

  .mobile-nav-mask
    position fixed
    top 0
    right 0
    bottom 0
    left 0
    background rgba(0, 0, 0, .7)
    z-index 999

  .mobile-nav-show
    right 0
    transition .3s

  .sm-title
    font-size 14px
    margin 20px
</style>